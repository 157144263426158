.app_board {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 0;
}

.app_icon {
    min-width: 7rem;
    max-width: 12rem;
    width: 20vmin;
    border-radius: 21.4%;
    box-shadow: 0 0 3rem rgba(31, 31, 31, 0.12);
    /* filter: drop-shadow(0 0 3rem rgba(31, 31, 31, 0.12)); */
}

.app_title {
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    margin-top: 2.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inherit;
}

.app_subtitle {
    font-size: 1.1rem;
    margin: 0;
    margin-top: 2rem;
}

.download_group {
    --download-button-spcae: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.8rem;
    margin-bottom: var(--download-button-spcae);
}

.download_button {
    height: 2.8rem;
    margin-top: var(--download-button-spcae);
}

@media screen and (min-width: 1024px) {
    .app_board {
        padding: 5rem 0;
    }

    .app_title {
        font-size: 1.7rem;
        margin-top: 4rem;
    }
    .app_subtitle {
        font-size: 1.4rem;
        margin-top: 3rem;
    }

    .download_button {
        height: 3.2rem;
    }
} 

@media (prefers-color-scheme: dark) {
    .app_icon {
        box-shadow: 0 0 3rem rgba(221, 221, 221, 0.12);
        /* filter: drop-shadow(0 0 3rem rgba(221, 221, 221, 0.12)); */
    }
}