.background {
    width: 100vw;
    min-height: calc(100vh - var(--copy-right-height));
    min-height: calc(100svh - var(--copy-right-height));
    display: flex;            
    display: -webkit-flex;
    flex-direction: column;        
    justify-content: center;            
    align-items: center;    
}

.studio_name {
    font-size: 2rem;
    font-weight: 600;
    font-style: italic;
    justify-self: center;
}

.xxx {
    margin-top: 1.6rem;
}

.xxx span {
    display: none;
}
