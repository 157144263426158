.copy_right_box {
    height: var(--copy-right-height);
    display: flex;
    justify-content: center;
    align-items: center;
}

.copy_right {
    font-size: calc(var(--copy-right-height) * 0.45);
}
